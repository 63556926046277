import db from "@/config/db"
import axios from "axios"
import Vue, { VueConstructor, getCurrentInstance } from "vue"
import api from "./api"

const useCore = () => {
  const instance: any = getCurrentInstance()
  const vm = instance?.proxy || (instance as unknown as InstanceType<VueConstructor>) || new Vue({})

  return {
    api: vm.$api as typeof api,
    graphql: vm.$graphql as <T = any>(
      query: string,
      params?: any,
      shouldCacheResult?: boolean,
      forceRefresh?: boolean
    ) => Promise<T>,
    axios: vm.$axios as typeof axios,
    db: vm.$db as typeof db,
    root: vm.$root,
  }
}

export default useCore
