<script lang="ts" setup>
import useCore from "@/plugins/use-core"
import { log } from "console"
import { onMounted, ref } from "vue"
import useI18n from "@/plugins/use-i18n"

const { publishing } = defineProps<{
  publishing: { id: number }
}>()

const emit = defineEmits(["close"])

const { graphql } = useCore()

const { t } = useI18n()
const $t = (key: string) => t(`detailView.portalExport.${key}`)

const data = ref<any>(null)
const loading = ref(false)

onMounted(async () => {
  loading.value = true
  try {
    const {
      publishing: { externalData },
    } = await graphql(`
  query {
    publishing(id: ${publishing.id}) {
      externalData
    }
  }`)

    data.value = JSON.stringify(externalData, null, 2)
  } finally {
    loading.value = false
  }
})
</script>

<template>
  <el-dialog :title="$t('idealistaData')" visible append-to-body @close="emit('close')">
    <spinner v-if="loading" />
    <pre v-else-if="data">
      <code class="block whitespace-pre-wrap p-3">{{ data }}</code>
    </pre>
    <alert v-else type="danger">
      {{ $t("idealistaNoData") }}
    </alert>
  </el-dialog>
</template>
